export const source = `
<header class="header-block">
    <div class="flex justify-center">
        <nav class="self-center w-full max-w-7xl  nav-block">
            <div class="flex flex-col lg:flex-row justify-around items-center">
                <h1 class="uppercase pl-5 py-4 text-lg font-sans font-bold">hero</h1>
                <ul class="hidden lg:flex items-center text-[18px] font-semibold pl-32">
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#">Home</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#">Contact</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#">Services</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#">About</a>
                    </li>
                    <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                        <a href="#">Pricing</a>
                    </li>
                </ul>
                <div class="text-center text-base pr-5 inline-flex">
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[6px] hover:text-blue-500"><i class="fa fa-twitter"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i class="fa fa-instagram"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i class="fa fa-facebook"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i class="fa fa-google"></i></a>
                    <a href="#" class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i class="fa fa-linkedin"></i></a>
                </div>
            </div>
        </nav>
    </div>
    
    <div class="flex justify-center">
        <div class="flex flex-col justify-center">
            <div class="flex flex-col max-w-7xl justify-center items-center p-2 mt-10">
                <div class="flex flex-col w-[90%] md:w-3/4 text-center items-center justify-center space-y-3">
                    <div class="text-5xl font-bold">Clean Green Crypto Energy for Crypto Mining</div>
                    <div class="text-xl font-semibold text-gray-400">Help save the planet with KWATT tokenized electricity</div>
                    <div class="md:text-lg">
                        <input
                            class="bg-gray-200/70 border drop-shadow-sm shadow-gray-700 rounded-2xl placeholder-slate-400 px-4 py-1 focus:outline-none"
                            type="email"
                            placeholder="E-mail Address"
                        />
                        <button
                            class="p-1 m-2 rounded-2xl text-white bg-blue-500 px-4 font-semibold hover:bg-blue-900 hover:trandform ease-in-out duration-300"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
                <div class="w-3/4 h-60 md:h-96 my-4 rounded-lg overflow-hidden">
                    <img src="https://source.unsplash.com/500x400/?cryptocurrency,electricity" class="h-full w-full" alt="" />
                </div>
            </div>
        </div>
    </div>
</header>
`;
