import { useEffect, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import useAxios from "../Hooks/useAxios";
import { toast } from "react-toastify";

const FileParser = ({ tableData, selectSecondOrFirst, onUpdateTable }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [parsedData, setParsedData] = useState([]);
  const [formData, setFormData] = useState({});
  const [token, setToken] = useState("");
  const [ignoreFormat, setIgnoreFormat] = useState(false);
  const { sendRequest, data, error } = useAxios("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (data) {
      toast.success(`Success ${tableData.table_name}`);
      tableData.table_data[0] = data;
      onUpdateTable(tableData);
      setFormData({});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(`Error ${error.message}`);
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const tableStructure = JSON.parse(tableData.table_structure);

    let formattedData = [];

    if (ignoreFormat) {
      if (parsedData[0].length < tableStructure.length) {
        toast.error("File format is incorrect: too few columns");
        return;
      }
      formattedData = parsedData.map((row) => {
        const formattedRow = {};
        tableStructure.forEach((column, index) => {
          formattedRow[column.name] = row[index] || "";
        });
        return formattedRow;
      });
    } else {
      if (Object.keys(parsedData[0]).length !== tableStructure.length) {
        toast.error("File format is incorrect: column count mismatch");
        return;
      }
      formattedData = parsedData.map((row) => {
        const formattedRow = {};
        tableStructure.forEach((column) => {
          formattedRow[column.name] = row[column.name] || "";
        });
        return formattedRow;
      });
    }

    if (selectSecondOrFirst === "second") {
      const jsonData = JSON.parse(tableData.table_data[0].data);
      jsonData.push(...formattedData);
      const data = { user_tables_id: tableData.id, data: jsonData };
      sendRequest("patch", data, {
        url: `${apiUrl}/data-tables/${tableData.table_data[0].id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else if (selectSecondOrFirst === "first") {
      const jsonData = formattedData;
      const data = { user_tables_id: tableData.id, data: jsonData };
      sendRequest("post", data, {
        url: `${apiUrl}/data-tables`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;

    if (fileType === "text/csv") {
      parseCSV(file);
    } else if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
      parseExcel(file);
    } else {
      alert("Please upload a valid CSV or Excel file.");
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: 0,
      complete: (result) => {
        setParsedData(result.data);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setParsedData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      <div>
        <input type="checkbox" checked={ignoreFormat} onChange={() => setIgnoreFormat(!ignoreFormat)} />
        <label>Ignore format</label>
      </div>
      {parsedData.length > 0 && (
        <>
          <div className="flex justify-start mt-2">
            <button
              onClick={handleSubmit}
              style={{ backgroundColor: "#6565e5" }}
              className="inline-flex justify-left mt-2 px-4 py-2 text-white rounded-md appearance-none"
              type="submit"
            >
              Save Changes
            </button>
          </div>
          <div className="overflow-y-auto">
            <table className="min-w-full divide-y divide-gray-200 overflow-auto">
              <thead className="bg-gray-50">
                <tr>
                  {JSON.parse(tableData.table_structure).map((column) => (
                    <th key={column.name} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <thead className="bg-gray-50">
                <tr>
                  {JSON.parse(tableData.table_structure).map((column, colIdx) => (
                    <th key={colIdx} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {String(ignoreFormat ? parsedData[0][colIdx] : parsedData[0][column.name] || "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {parsedData.map(
                  (row, index) =>
                    index > 0 && (
                      <tr key={index} className="text-black">
                        {JSON.parse(tableData.table_structure).map((column, colIdx) => (
                          <td key={colIdx} className="px-6 py-4 white-space:no-wrap">
                            {String(ignoreFormat ? row[colIdx] : row[column.name] || "")}
                          </td>
                        ))}
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default FileParser;
