export const source = `   <div class="flex justify-center bg-black">
<nav class="self-center w-full max-w-7xl  ">
    <div class="flex flex-col lg:flex-row justify-around items-center text-white">
        <h1 class="uppercase pl-5 py-4 text-lg font-sans font-bold">hero</h1>
        <ul class="hidden lg:flex items-center text-[18px] font-semibold pl-32">
            <li class="hover:underline  underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5">
                <a href="#">Home</a>
            </li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5"><a
                    href="#">Contact</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5"><a
                    href="#">Services</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5"><a
                    href="#">About</a></li>
            <li class="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-5"><a
                    href="#">Pricing</a></li>
        </ul>
        <div class="text-white text-center text-base pr-5  inline-flex"> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[6px] hover:text-blue-500"><i
                    class="fa fa-twitter"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-instagram"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-facebook"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-google"></i></a> <a href="#"
                class="w-8 h-8 inline-block rounded-full pt-[5px] hover:text-blue-500"><i
                    class="fa fa-linkedin"></i></a> </div>
    </div>
</nav>
</div>
<div class="flex justify-center bg-black p-8 ">
<div class="flex flex-col justify-center">

    <div class="flex flex-col lg:flex-row max-w-7xl justify-center items-center p-2 space-y-3 w-full">
        <div class="flex flex-col  text-white md:items-start items-center justify-between  space-y-3 px-8">
            <div class="text-5xl md:text-7xl font-bold ">
                Hero Animation </div>
            <div class="text-lg uppercase md:text-3xl   ">
                Interactive Components</div>
            <div class="text-xl md:text-3xl   ">
                @tailblocks.org</div>
        </div>
        <div class="flex space-x-2 md:space-x-6 md:m-4">

            <div class="md:w-20 w-10 h-60 md:h-96  overflow-hidden rounded-xl">
                <img src="https://source.unsplash.com/100x400/?man" class="h-full w-full" alt="">
            </div>
            <div class="md:w-60 w-28 h-60 md:h-96  overflow-hidden rounded-xl">
                <img src="https://source.unsplash.com/200x400/?girl" class="h-full w-full" alt="">

            </div>
            <div class="md:w-28  w-16 h-60 md:h-96  overflow-hidden rounded-xl">
                <img src="https://source.unsplash.com/100x400/?boy" class="h-full w-full" alt="">

            </div>
            <div class="md:w-20 w-10 h-60 md:h-96  overflow-hidden rounded-xl">
                <img src="https://source.unsplash.com/100x400/?women" class="h-full w-full" alt="">

            </div>
        </div>

    </div>
</div>
</div>`;
