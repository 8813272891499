export const source =`<body class="body__lp4">
<header>
    <nav>
        <div class="logo">
            <img src="Images/Logo.png">
        </div>
        <div class="links">
            <a href="#">Documentation</a>
            <button id="goToForm">Sign up</button>
        </div>
    </nav>
</header>

<main>
    <div class="main-content-wrapper">
        <div id="btn-to-top" onclick="toTop()">
            <img src="Images/btn-to-top.png">
        </div>
        <div class="hero">
            <h1>Landing template for startups</h1>
            <div class="text-wrapper-1">
                <p>Our landing page template works on all devices, so you only have to
                    set it up once, and get beautiful results forever.</p>
            </div>
            <div class="group-btns">
                <button>Start free trial</button>
                <button>Learn more</button>
            </div>
            <div class="video">
                <img src="Images/Video.png">
            </div>
        </div>
        <div class="part-1">
            <h2>Build up the whole picture</h2>
            <div class="text-wrapper-2">
                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                    est
                    laborum — semper quis lectus nulla at volutpat diam ut venenatis.</p>
            </div>
            <div class="container-1">
                <div class="blocks-wrapper-1">
                    <div class="block">
                        <img src="Images/Icon.png">
                        <h4>Instant Features</h4>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                            nulla
                            pariatur. Excepteur sint occaecat cupidatat.</p>
                    </div>
                    <div class="block">
                        <img src="Images/Icon-2.png">
                        <h4>Instant Features</h4>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                            nulla
                            pariatur. Excepteur sint occaecat cupidatat.</p>
                    </div>
                    <div class="block">
                        <img src="Images/Icon-3.png">
                        <h4>Instant Features</h4>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                            nulla
                            pariatur. Excepteur sint occaecat cupidatat.</p>
                    </div>
                    <div class="block">
                        <img src="Images/Icon-4.png">
                        <h4>Instant Features</h4>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                            nulla
                            pariatur. Excepteur sint occaecat cupidatat.</p>
                    </div>
                    <div class="block">
                        <img src="Images/Icon-5.png">
                        <h4>Instant Features</h4>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                            nulla
                            pariatur. Excepteur sint occaecat cupidatat.</p>
                    </div>
                    <div class="block">
                        <img src="Images/Icon-6.png">
                        <h4>Instant Features</h4>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                            nulla
                            pariatur. Excepteur sint occaecat cupidatat.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="part-2">
            <button>Reach goals that matter</button>
            <h2>One product, unlimited solutions</h2>
            <div class="text-wrapper-3">
                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit laborum — semper quis lectus nulla.</p>
            </div>
            <div class="container-2">
                <div class="blocks-wrapper-2">
                    <!--rows-1-->
                    <div class="row">
                        <div class="text-block">
                            <h5>More speed. Less spend</h5>
                            <h3>Keep projects on schedule</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt
                                ut
                                labore et dolore magna aliqua.</p>
                            <ul>
                                <li>Duis aute irure dolor in reprehenderit</li>
                                <li>Excepteur sint occaecat</li>
                                <li>Amet consectetur adipiscing elit</li>
                            </ul>
                        </div>
                        <div class="image-block">
                            <img src="Images/Illustration.png">
                        </div>
                    </div>
                    <!--rows-2-->
                    <div class="row" style="flex-direction: row-reverse;">
                        <div class="text-block">
                            <h5>More speed. Less spend</h5>
                            <h3>Keep projects on schedule</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt
                                ut
                                labore et dolore magna aliqua.</p>
                            <ul>
                                <li>Duis aute irure dolor in reprehenderit</li>
                                <li>Excepteur sint occaecat</li>
                                <li>Amet consectetur adipiscing elit</li>
                            </ul>
                        </div>
                        <div class="image-block">
                            <img src="Images/Illustration-2.png">
                        </div>
                    </div>
                    <!--rows-3-->
                    <div class="row">
                        <div class="text-block">
                            <h5>More speed. Less spend</h5>
                            <h3>Keep projects on schedule</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt
                                ut
                                labore et dolore magna aliqua.</p>
                            <ul>
                                <li>Duis aute irure dolor in reprehenderit</li>
                                <li>Excepteur sint occaecat</li>
                                <li>Amet consectetur adipiscing elit</li>
                            </ul>
                        </div>
                        <div class="image-block">
                            <img src="Images/Illustration-3.png">
                        </div>
                    </div>
                    <!--The end-->
                </div>
            </div>
        </div>
        <div class="part-3">
            <h2>Don't take our word for it</h2>
            <div class="text-wrapper-4">
                <p>Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at
                    volutpat
                    diam ut venenatis tellus—in ornare.</p>
            </div>
            <div class="container-3">
                <div class="blocks-wrapper-3">
                    <div class="row">
                        <!--tab-1-->
                        <div class="tab">
                            <img src="Images/Avatar.png">
                            <p>— Open PRO lets me quickly get the insights I care about so that I can focus on my
                                productive
                                work. I've had Open PRO for about 24 hours now and I honestly don't know how I
                                functioned
                                without it before.</p>
                            <div class="bottom-text">
                                <span class="name">Anastasia Dan</span>
                                <span class="some-text">UX Board</span>
                            </div>
                        </div>
                        <!--tab-2-->
                        <div class="tab">
                            <img src="Images/Avatar-2.png">
                            <p>— Open PRO lets me quickly get the insights I care about so that I can focus on my
                                productive
                                work. I've had Open PRO for about 24 hours now and I honestly don't know how I
                                functioned
                                without it before.</p>
                            <div class="bottom-text">
                                <span class="name">Roman Level</span>
                                <span class="some-text">AppName</span>
                            </div>
                        </div>
                        <!--tab-3-->
                        <div class="tab">
                            <img src="Images/Avatar-3.png">
                            <p>— Open PRO lets me quickly get the insights I care about so that I can focus on my
                                productive
                                work. I've had Open PRO for about 24 hours now and I honestly don't know how I
                                functioned
                                without it before.</p>
                            <div class="bottom-text">
                                <span class="name">Akex Sackett</span>
                                <span class="some-text">Reform Layouts</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sign-up-form">
        <div class="sign-up-form-wrapper">
            <button class="btn-google">Sign up with Google</button>
            <p class="form-text-1"><span>Or, register with your email</span></p>
            <label>Full Name<br></label> <input type="text" placeholder="First and last name">
            <label>Company Name<span style='color: #707D86;'> *</span><br></label> <input type="text"
                placeholder="Your company or app name">
            <label>Work Email<span style='color: #707D86;'> *</span><br></label> <input type="text"
                placeholder="you@yourcompany.com">
            <label>Password<br></label> <input type="password" placeholder="Password (at least 10 characters)">
            <p class="form-text-2">I agree to be contacted by Open PRO about this offer as per the Open PRO <a
                    href="#">Privacy
                    Policy.</a></p>
            <button class="sign-up">Sign up</button>
            <div class="subtext">
                <p>Already using Open PRO?</p><a href="#">Sign in</a>
            </div>
        </div>
    </div>
</main>
<footer>
    <div class="subscribe">
        <div class="info-block">
            <h3>Stay in the loop</h3>
            <p style="color: #E2E1FF;">Join our newsletter to get top news before anyone else.</p>
        </div>
        <form action="#">
            <div class="form-wrapper">
                <input type="email" placeholder="Your best email…">
                <input type="submit" value="Subscribe">
            </div>
        </form>
    </div>
    <div class="additional-info">
        <div class="left-column">
            <div class="logo">
                <img src="Images/Logo.png">
            </div>
            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries
                for previewing layouts and visual mockups.</p>
        </div>
        <div class="right-columns">
            <div class="columns-wrapper">
                <!--column-1-->
                <div class="column">
                    <ul>
                        <li>Products</li>
                        <li><a href="#">Web Studio</a></li>
                        <li><a href="#">DynamicBox Flex</a></li>
                        <li><a href="#">Programming Forms</a></li>
                    </ul>
                </div>
                <!--column-2-->
                <div class="column">
                    <ul>
                        <li>Resources</li>
                        <li><a href="#">Nostrud exercitation</a></li>
                        <li><a href="#">Visual mockups</a></li>
                        <li><a href="#">Nostrud exercitation</a></li>
                        <li><a href="#">Visual mockups</a></li>
                        <li><a href="#">Nostrud exercitation</a></li>
                    </ul>
                </div>
                <!--column-3-->
                <div class="column">
                    <ul>
                        <li>Company</li>
                        <li><a href="#">Consectetur adipiscing</a></li>
                        <li><a href="#">Labore et dolore</a></li>
                        <li><a href="#">Consectetur adipiscing</a></li>
                        <li><a href="#">Labore et dolore</a></li>
                        <li><a href="#">Consectetur adipiscing</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="social">
        <div class="social-wrapper">
            <span class="info">© 2022 Open PRO. All rights reserved.</span>
            <div class="icon-group">
                <a href="https://twitter.com"><img src="Images/1.png"></a>
                <a href="https://github.com"><img src="Images/2.png"></a>
                <a href="https://facebook.com"><img src="Images/3.png"></a>
                <a href="https://instagram.com"><img src="Images/4.png"></a>
            </div>
        </div>
    </div>
</footer>
</body>
<script type="text/javascript" src="Js/scroll-to-top.js"></script>
<script type="module" src="Js/sign-up.js"></script>
<script type="module" src="Js/styles-switcher.js"></script>`;