import type { Editor } from 'grapesjs';

export const registerButton = (editor: Editor) => {
    editor.Commands.add('save-json', {
        run: (editor) => {
            // Отримуємо та трансформуємо компоненти
            const textarea = document.createElement('textarea');
            textarea.style.width = '100%';
            textarea.style.height = '750px';
            textarea.style.color = 'black';
            const jsonAll = editor.Pages.getSelected().collection.map((p) => {
                editor.Pages.select(p);
                const css = editor.getCss();
                console.log('pages ', css);
                return {
                    name: p.attributes.name,
                    id: p.getMainComponent().getId(),
                    pages: p.getMainComponent().toJSON(),
                    css: css,
                };
            });

            function findComponentsWithAttribute(pages, attribute) {
                function traverse(components) {
                    if (Array.isArray(components)) {
                        for (let index = components.length - 1; index >= 0; index--) {
                            const component = components[index];
                            if (component.attributes && component.attributes[attribute] === 'true') {
                                components.splice(index, 1);
                            } else if (component.components) {
                                traverse(component.components);
                            }
                        }
                    }
                }
                traverse(pages.components);
                return pages;
            }

            jsonAll.forEach((item) => {
                const components = findComponentsWithAttribute(JSON.parse(JSON.stringify(item.pages, null, 2)), 'data-display-only');
                console.log(`Components with 'data-display-only' attribute in ${item.name}: `, components);
                item.pages = components;
            });

            textarea.value = JSON.stringify(jsonAll, null, 2);

            // function jsonToCss(jsonStyles) {
            //     if (jsonStyles.length === 0) return;
            //     return jsonStyles.map(styleObj => {
            //         const selectors = styleObj.selectors.join(", ");
            //         const styles = Object.entries(styleObj.style).map(([key, value]) => {
            //             return `${key}: ${value};`;
            //         }).join(" ");
            //         return `${selectors} { ${styles} }`;
            //     }).join(" ");
            // }
            // console.log("pages", editor.Pages, "editor-", editor);
            // const stylesJson = editor.getStyle();
            // const cssContent = editor.getCss();
            // console.log("css", jsonToCss(JSON.parse(JSON.stringify(stylesJson, null, 2))), "css-", cssContent);
            //  console.log('parse-', JSON.parse(textarea.value));
            //  editor.setComponents(JSON.parse(textarea.value)[0].pages.components);

            editor.Modal.setTitle('Components JSON').setContent(textarea).open();
        },
    });

    editor.Commands.add('html-import', {
        run: function (editor) {
            const codeViewer = editor.CodeManager.getViewer('CodeMirror').clone();
            codeViewer.set({
                codeName: 'htmlmixed',
                readOnly: false,
                theme: 'hopscotch',
                autoBeautify: true,
                autoCloseTags: true,
                autoCloseBrackets: true,
                lineWrapping: true,
                styleActiveLine: true,
                smartIndent: true,
                indentWithTabs: true,
            });

            const container = document.createElement('div');
            const btnEdit = document.createElement('button');
            btnEdit.innerHTML = 'Import';
            btnEdit.onclick = function () {
                const code = codeViewer.editor.getValue().trim();
                // Розділити HTML, CSS і JavaScript
                const htmlContent = code.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, '');  
                // getJs()
                // Очищення старого контенту
                editor.DomComponents.getWrapper().set('content', '');
                const content = htmlContent;
                editor.setComponents(content);
              
                editor.Modal.close();
            };
            let viewer = codeViewer.editor;
            editor.Modal.setTitle('Edit code');
            if (!viewer) {
                const txtarea = document.createElement('textarea');
                container.appendChild(txtarea);
                codeViewer.init(txtarea);
                viewer = codeViewer.editor;
            }
            container.appendChild(btnEdit);
            //editor.getJs()
            const currentComponents = editor.getHtml() + '<style>' + editor.getCss() + '</style>';
            codeViewer.setContent(currentComponents);

            // editor.Modal.setContent("");
            editor.Modal.setContent(container);
            editor.Modal.open();

            // viewer.refresh(); // Оновлюємо редактор коду для коректного відображення
            setTimeout(function () {
                const modalEl: HTMLElement | null = document.querySelector('.CodeMirror');
                if (modalEl) {
                    // modalEl.style.width = '100%';
                    // modalEl.style.height = '100%';
                }
                viewer.refresh();
            }, 100);
        },
    });

    editor.Commands.add('json-import', {
        run: function (editor) {
            const modalContent = document.createElement('div');
            const importButton = document.createElement('button');
            const textarea = document.createElement('textarea');
            textarea.style.width = '100%';
            textarea.style.height = '350px';
            textarea.style.color = 'black';
            modalContent.appendChild(textarea);

            importButton.textContent = 'Import JSON';
            importButton.onclick = function () {
                try {
                    const jsonInput = JSON.parse(textarea.value);
                    //editor.Pages.getAll().reset();
                    jsonInput.forEach((pageData) => {
                        const page = editor.Pages.add({ name: pageData.name });
                        editor.Pages.select(page);
                        editor.setComponents(pageData.pages); // Встановлення компонентів сторінки
                        editor.setStyle(pageData.css); // Встановлення CSS для сторінки
                    });

                    editor.Modal.close();
                } catch (e) {
                    alert('Invalid JSON');
                    console.error(e);
                }
            };

            modalContent.appendChild(importButton);
            editor.Modal.setTitle('Import JSON').setContent(modalContent).open();
        },
    });

    editor.Commands.add('clear', {
        run: function (editor) {
            editor.DomComponents.clear();
            editor.CssComposer.clear();
            //   editor.StyleManager.clear();
            //  editor.UndoManager.clear();
            //  editor.AssetManager.clear();
            //  editor.BlockManager.clear();
        },
    });

    editor.Commands.add('change_mode', {
        run: function (editor) {
            editor.getModel().set('dmode', 'absolute');
        },
        stop: function (editor) {
            editor.getModel().set('dmode', 'default');
        },
    });

    /* ALLOW AND DISABLE SCRIPTS */
    editor.Commands.add('allowScripts', {
        run: function (editor) {
            console.log('----> Scripts Enabled <----');
            (editor.getConfig() as any).allowScripts = 0;
        },
        stop: function (editor) {
            console.log('----> Scripts Disabled <----');
            (editor.getConfig() as any).allowScripts = 1;
        },
    });
};
