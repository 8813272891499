import React, { useState } from 'react';
import TemplateSubMenu from './Submenu/TemplateSubMenu';
import EditSubMenu from './Submenu/EditSubMenu';
import HeaderSubMenu from './Submenu/HeaderSubMenu';
import { WithEditor } from '@grapesjs/react';

const GeneralMenu = () => {
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        { name: 'Template', component: TemplateSubMenu },
        { name: 'Edit', component: EditSubMenu },
        { name: 'Layout', component: null },
        {
            name: 'Header',
            component: () => (
                <WithEditor>
                    <HeaderSubMenu />
                </WithEditor>
            ),
        },
        { name: 'Blocks', component: null },
        { name: 'Sidebar', component: null },
        { name: 'Background', component: null },
    ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="general_menu">
            <div id="tabs-container">
                <ul className="general_menu__tabs">
                    {tabs.map((tab, index) => (
                        <li key={index} className={activeTab === index ? 'active' : ''}>
                            <button onClick={() => handleTabClick(index)}>{tab.name}</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="content" id="nav-container">
                {activeTab !== null && tabs[activeTab].component && React.createElement(tabs[activeTab].component)}
            </div>
        </div>
    );
};

export default GeneralMenu;
