import { useCallback } from 'react';

const useProjectJson = (setProjectNow) => {
    const setProjectJson = useCallback(
        (editor, value) => {
            try {
                const jsonInput = JSON.parse(value);
                setProjectNow((prevProjectNow) => ({ ...prevProjectNow, json: jsonInput }));
                const pages = editor.Pages.getAll();
                pages.forEach((page) => editor.Pages.remove(page.id));
                editor.Pages.clear();
                jsonInput.forEach((pageData) => {
                    const page = editor.Pages.add({ name: pageData.name }, true);
                    editor.Pages.select(page);
                    editor.setComponents(pageData.pages);
                    editor.setStyle(pageData.css);
                });
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        [setProjectNow]
    );

    return { setProjectJson };
};

export default useProjectJson;
