import type { Editor } from "grapesjs";

import loadTemplateBlocks from './Template';
import loadTeamsBlocks from './Teams';
import loadStatsBlocks from './Stats';
import loadHeaderBlocks from './Header';
import loadTeamBlocks from './Teams';
import load404Blocks from './404';
import loadTestimonialsBlocks from './Testimonials';
import loadLoginBlocks from './Login';
import loadCardBlocks from './Card';
import loadPostBlocks from './Post';
import loadContactBlocks from './Contact';
import loadNavigationBlocks from './Navigation';
import loadButtonsBlocks from './Buttons';
import loadPaginationBlocks from './Pagination';
import loadShareButtonsBlocks from './ShareButtons';
import loadFooterBlocks from './Footer';
import loadModalBlocks from './Modal';
import loadGridBlocks from './Grid';
import loadTableIdeaBlocks from './TableIdea';
import loadMarketingBlocks from './Marketing';
import loadDropdownBlocks from './Dropdown';
import loadAlertBlocks from './Alert';
import loadAvatarsBlocks from './Avatars';
import loadBadgesBlocks from './Badges';
import loadBreadcrumbsBlocks from './Breadcrumbs';
import loadButtonsGroupBlocks from './ButtonsGroup';
import loadClipBoardBlocks from './ClipBoard';
import loadDatePickerBlocks from './DatePicker';
import loadFileUploadBlocks from './FileUpload';
import loadGalleryBlocks from './Gallery';
import loadSkeletonBlocks from './Skeleton';
import loadListsBlocks from './Lists';

export const PluginsIdeasComponents = (editor: Editor) => {
  loadTemplateBlocks(editor);
  loadTeamsBlocks(editor);
  loadStatsBlocks(editor);
  loadHeaderBlocks(editor);
  loadTeamBlocks(editor);
  load404Blocks(editor);
  loadTestimonialsBlocks(editor);
  loadLoginBlocks(editor);
  loadCardBlocks(editor);
  loadPostBlocks(editor);
  loadNavigationBlocks(editor);
  loadContactBlocks(editor);
  loadButtonsBlocks(editor);
  loadPaginationBlocks(editor);
  loadShareButtonsBlocks(editor);
  loadFooterBlocks(editor); 
  loadModalBlocks(editor); 
  loadGridBlocks(editor); 
  loadTableIdeaBlocks(editor); 
  loadMarketingBlocks(editor); 
  loadDropdownBlocks(editor); 
  loadAlertBlocks(editor); 
  loadAvatarsBlocks(editor); 
  loadBadgesBlocks(editor); 
  loadBreadcrumbsBlocks(editor); 
  loadButtonsGroupBlocks(editor);
  loadClipBoardBlocks(editor);  
  loadDatePickerBlocks(editor);  
  loadFileUploadBlocks(editor); 
  loadGalleryBlocks(editor); 
  loadSkeletonBlocks(editor);
  loadListsBlocks(editor);  
};
